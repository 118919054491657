import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {OrganizationService} from "../../../core/services/organization.service";

@Component({
  selector: 'app-membership-enquiry-dialog',
  templateUrl: './membership-enquiry-dialog.component.html',
  styleUrls: ['./membership-enquiry-dialog.component.scss']
})
export class MembershipEnquiryDialogComponent implements OnInit {

  enquiryForm: FormGroup = new FormGroup({
    firstName: new FormControl('', []),
    lastName: new FormControl('', []),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required,]),
    message: new FormControl('', []),
  })
  title: string = 'Membership Enquiry'
  info: string = ''

  constructor(public dialogRef: MatDialogRef<MembershipEnquiryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private organizationService: OrganizationService,
  ) {
    if(!!data.title) {
      this.title = data.title;
    }
    if(!!data.info) {
      this.info = data.info;
    }
    this.enquiryForm.patchValue({
      firstName: this.data.firstName,
      lastName: this.data.lastName,
      email: this.data.email,
      phone: this.data.phone,
    })
  }

  ngOnInit(): void {
  }

  get form(): { [key: string]: AbstractControl; } {
    return this.enquiryForm.controls;
  }

  closeDialog() {
    this.enquiryForm.markAllAsTouched();
    if (this.enquiryForm.valid) {
      this.organizationService.createMembershipEnquiry({
        first_name: this.enquiryForm.value.firstName,
        last_name: this.enquiryForm.value.lastName,
        email: this.enquiryForm.value.email,
        phone: this.enquiryForm.value.phone,
        description: this.enquiryForm.value.message
      }).subscribe(() => {
        this.dialogRef.close({data: 'confirm'});
      }, err => {
        this.dialogRef.close({data: 'error'});
      })
    }


  }

}
