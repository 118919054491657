<div class="authentication-layout-container">
  <div class="layout-wrapper">
    <div class="content-wrapper">
      <div class="icon-section">
        <img src="assets/icons/login-logo.svg" alt="cms-log">
      </div>
      <div class="content-section">
        <router-outlet></router-outlet>
      </div>
    </div>

  </div>
</div>

