import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {AuthenticationService} from "../services/authentication.service";
import {PermissionService} from "../services/permission.service";

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService,
              private permService: PermissionService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let currentUser = this.authService.currentUserValue;
    if (currentUser && currentUser.access) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.access}`
        }
      });
      this.permService.isRoleVersionChange();
    } else {
      this.authService.logout();
      return EMPTY;
    }
    return next.handle(request);
  }
}
