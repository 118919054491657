import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthenticationLayoutComponent} from './layouts/authentication-layout/authentication-layout.component';
import {MainLayoutComponent} from './layouts/main-layout/main-layout.component';
import {EmailMaskPipe} from './pipes/email-mask.pipe';
import {RouterModule} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import {ArticleCardComponent} from './components/article-card/article-card.component';
import {ArticleFilterComponent} from './components/article-filter/article-filter.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {VideoCardComponent} from './components/video-card/video-card.component';
import {DragUploadDirective} from './directives/drag-upload.directive';
import {AuthImagePipe} from './pipes/auth-image.pipe';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {FileSizePipe} from './pipes/file-size.pipe';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ShortNumberPipe} from './pipes/short-number.pipe';
import {HelpCenterDialogComponent} from './components/help-center-dialog/help-center-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MembershipLayerComponent} from './components/membership-layer/membership-layer.component';
import {
  FunctionRestrictedDialogComponent
} from './components/function-restricted-dialog/function-restricted-dialog.component';
import {
  MembershipEnquiryDialogComponent
} from './components/membership-enquiry-dialog/membership-enquiry-dialog.component';
import {ProjectListingTableComponent} from './components/project-listing-table/project-listing-table.component';
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {
  ProjectSourceLinkDialogComponent
} from './components/project-source-link-dialog/project-source-link-dialog.component';
import {ProjectNoteDialogComponent} from './components/project-note-dialog/project-note-dialog.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {
  ProjectSelectionDialogComponent
} from './components/project-selection-dialog/project-selection-dialog.component';
import { ProjectFilterComponent } from './components/project-filter/project-filter.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { ProjectSelectionConfirmDialogComponent } from './components/project-selection-confirm-dialog/project-selection-confirm-dialog.component';
import { ProjectBatchInvitationListingDialogComponent } from './components/project-batch-invitation-listing-dialog/project-batch-invitation-listing-dialog.component';
import { PromotionInfoDialogComponent } from './components/promotion-info-dialog/promotion-info-dialog.component';
import { IframeViewerDialogComponent } from './components/iframe-viewer-dialog/iframe-viewer-dialog.component';
import { AiDialogComponent } from './components/ai-dialog/ai-dialog.component';
import {MatListModule} from "@angular/material/list";


@NgModule({
  declarations: [
    AuthenticationLayoutComponent,
    MainLayoutComponent,
    EmailMaskPipe,
    BreadcrumbComponent,
    ArticleCardComponent,
    ArticleFilterComponent,
    VideoCardComponent,
    DragUploadDirective,
    AuthImagePipe,
    FileSizePipe,
    ShortNumberPipe,
    MembershipLayerComponent,
    HelpCenterDialogComponent,
    FunctionRestrictedDialogComponent,
    MembershipEnquiryDialogComponent,
    ProjectListingTableComponent,
    ProjectSourceLinkDialogComponent,
    ProjectNoteDialogComponent,
    ProjectSelectionDialogComponent,
    ProjectFilterComponent,
    ProjectSelectionConfirmDialogComponent,
    ProjectBatchInvitationListingDialogComponent,
    PromotionInfoDialogComponent,
    IframeViewerDialogComponent,
    AiDialogComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        FormsModule,
        MatMenuModule,
        MatButtonModule,
        MatCardModule,
        MatProgressBarModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatListModule,
    ],
  exports: [
    AuthenticationLayoutComponent,
    MainLayoutComponent,
    EmailMaskPipe,
    AuthImagePipe,
    FileSizePipe,
    ShortNumberPipe,
    FlexLayoutModule,
    ArticleFilterComponent,
    ArticleCardComponent,
    VideoCardComponent,
    DragUploadDirective,
    MembershipLayerComponent,
    ProjectListingTableComponent,
  ]
})
export class SharedModule {
}
