<div mat-dialog-title>
  <div class="">{{title}}</div>
  <div class="close-icon" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </div>
</div>
<mat-dialog-content class="enquiry-form unit-filed-wrapper">


  <form [formGroup]="enquiryForm">
    <div *ngIf="info" class="info-line">
      {{info}}
    </div>
    <div class="same-line-wrap">
      <mat-form-field appearance="outline" class="body-2">
        <mat-label>First name</mat-label>
        <input matInput type="text" formControlName="firstName" placeholder="First name">
      </mat-form-field>

      <mat-form-field appearance="outline" class="body-2">
        <mat-label>Last name</mat-label>
        <input matInput type="text" formControlName="lastName" placeholder="Last name">
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline" class="body-2">
      <mat-label>Email</mat-label>
      <input matInput type="email" formControlName="email" placeholder="Email">
      <mat-error
        *ngIf="enquiryForm.invalid && enquiryForm.touched && form['email'].errors && form['email'].errors['email']">
        Wrong email format.
      </mat-error>
      <mat-error
        *ngIf="enquiryForm.invalid && enquiryForm.touched && form['email'].errors && form['email'].errors['required']">
        Email is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="body-2">
      <mat-label>Phone</mat-label>
      <input matInput type="text" formControlName="phone" placeholder="Phone">
      <mat-error
        *ngIf="enquiryForm.invalid && enquiryForm.touched && form['phone'].errors && form['phone'].errors['required']">
        Phone number is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="body-2">
      <mat-label>Message</mat-label>
      <textarea matInput type="text" formControlName="message" placeholder="Message" rows="5"></textarea>
    </mat-form-field>
  </form>

  <div class="button-container">
    <button class="save-btn global-button" (click)="closeDialog()">
      Confirm
    </button>
  </div>

</mat-dialog-content>
