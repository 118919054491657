import {Component, Inject, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-help-center-dialog',
  templateUrl: './help-center-dialog.component.html',
  styleUrls: ['./help-center-dialog.component.scss']
})
export class HelpCenterDialogComponent implements OnInit {

  constructor(private router: Router,
              public dialogRef: MatDialogRef<HelpCenterDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,) {
  }

  ngOnInit(): void {
  }

  openMenu() {
    this.router.navigateByUrl(`/manage/menu`);
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }


}
