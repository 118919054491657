import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {map, Observable, tap} from "rxjs";
import {
  HubProjectListingCard,
  ProjectHubListingResponse,
  ProjectListingCard,
  ProjectListingResponse, ResponseHubProjectListingItem,
  ResponseProjectListingItem
} from "../interfaces/project.interface";
import {ProjectServiceInterface} from "../interfaces/project-service.interface";
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ProjectService implements ProjectServiceInterface {

  private httpWithoutInterceptor: HttpClient;
  projectCache: ProjectListingCard[] = [];

  constructor(private http: HttpClient, private datePipe: DatePipe, httpBackend: HttpBackend,) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend)
  }

  getProjectAgencies(page: number = 1, pageSize: number = 10, filters: any = null) {
    let params = new HttpParams();
    params = params.append('page', `${page}`)
    params = params.append('page_size', `${pageSize}`)
    params = params.append('manage', `${true}`)
    if (!!filters) {
      if ('project' in filters && !!filters.project) {
        params = params.append('project', `${filters.project}`)
      }
      if ('search' in filters && !!filters.search) {
        params = params.append('search', `${filters.search}`)
      }
      if ('project_search' in filters && !!filters.project_search) {
        params = params.append('project_search', `${filters.project_search}`)
      }
      if ('type' in filters && !!filters.type) {
        params = params.append('type', `${filters.type}`)
      }
      if ('accessibility' in filters && filters.accessibility !== undefined && filters.accessibility !== null) {
        params = params.append('is_authorized', `${filters.accessibility}`)
      }
      if ('agency' in filters && !!filters.agency) {
        params = params.append('agency', `${filters.agency}`)
      }
      if ('owner' in filters && !!filters.owner) {
        params = params.append('owner', `${filters.owner}`)
      }
    }
    return this.http.get(`${environment.urls.project.apiProjectAgentRelation}`, {params: params})
  }

  getUnitAgencies(page: number = 1, pageSize: number = 10, filters: any = null) {
    let params = new HttpParams();
    params = params.append('page', `${page}`);
    params = params.append('page_size', `${pageSize}`);
    if (!!filters) {
      if ('search' in filters && !!filters.search) {
        params = params.append('search', `${filters.search}`)
      }
      if ('unit' in filters && !!filters.unit) {
        params = params.append('unit', `${filters.unit}`)
      }
    }
    return this.http.get(`${environment.urls.project.apiUnitAgentRelation}`, {params: params})
  }

  createUnitAgency(data: any) {
    return this.http.post(`${environment.urls.project.apiUnitAgentRelation}`, data);
  }

  updateUnitAgency(id: string | null, data: any) {
    return this.http.patch(`${environment.urls.project.apiUnitAgentRelation}${id}/`, data);
  }

  deleteUnitAgency(id: string | null) {
    return this.http.delete(`${environment.urls.project.apiUnitAgentRelation}${id}/`);
  }

  createProjectAgentRelationExtra(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectAgentRelationExtra}`, data,)
  }

  updateProjectAgentRelationExtra(id: string | null, data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectAgentRelationExtra}${id}/`, data,)
  }

  updateProjectAgentRelation(id: string | null, data: any) {
    let params = new HttpParams();
    params = params.append('manage', `${true}`)
    return this.http.patch(`${environment.urls.project.apiProjectAgentRelation}${id}/`, data, {params: params})
  }

  updateSubAgencyStatus(id: string | null, data: any) {
    return this.http.post(`${environment.urls.project.apiProjectAgentRelation}${id}/par_is_authorized/`, data,)

  }

  getProjectInvitation(page: number = 1, pageSize: number = 10, filters: any = null) {
    let params = new HttpParams();
    params = params.append('page', `${page}`)
    params = params.append('page_size', `${pageSize}`)
    if (!!filters) {
      if ('project' in filters && !!filters.project) {
        params = params.append('project', `${filters.project}`)
      }
      if ('search' in filters && !!filters.search) {
        params = params.append('search', `${filters.search}`)
      }
      if ('accessibility' in filters && !!filters.accessibility) {
        params = params.append('accessibility', `${filters.accessibility}`)
      }
    }
    return this.http.get(`${environment.urls.project.apiProjectInvitation}`, {params: params})
  }

  deleteProjectInvitation(id: string | null) {
    return this.http.delete(`${environment.urls.project.apiProjectInvitation}${id}/`)
  }

  checkEmailExists(data: any) {
    return this.httpWithoutInterceptor.post(`${environment.urls.project.apiProjectInvitation}check_email_exists/`, data)
  }

  bulkCreateProjectInvitation(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectInvitation}bulk_create/`, data)
  }

  getBatchInviteProject(filters: any) {
    let params = new HttpParams();
    if (!!filters) {
      if ('project' in filters && !!filters.project) {
        params = params.append('project', `${filters.project}`)
      }
      if ('agency' in filters && !!filters.agency) {
        params = params.append('agency', `${filters.agency}`)
      }
    }
    return this.http.get(`${environment.urls.project.apiProjectBatchInvitation}`, {params: params})
  }

  deleteBatchInvitation(id: string | null) {
    return this.http.delete(`${environment.urls.project.apiProjectBatchInvitation}${id}/`)
  }

  batchInviteProject(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectBatchInvitation}`, data)
  }

  getBatchInvitationProjectInformation(data: any) {
    let params = new HttpParams();
    if (data.token) {
      params = params.append('token', `${data.token}`)
    }
    return this.http.get(`${environment.urls.project.apiProjectBatchInvitation}get_projects_info/`, {params: params})
  }

  getBatchInvitationProjectInformationThroughID(id: string | null) {
    return this.http.get(`${environment.urls.project.apiProjectBatchInvitation}${id}/get_projects_info_through_id/`)
  }

  confirmProjectInvitation(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectInvitation}confirm_invitation/`, data)
  }

  checkProjectBatchInvitation(get: any) {

  }

  confirmProjectBatchInvitation(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectBatchInvitation}confirm_invitation/`, data)
  }

  confirmProjectInvitationWithNewOrganization(data: any) {
    return this.httpWithoutInterceptor.post(`${environment.urls.project.apiProjectInvitation}confirm_invitation_with_new_org/`, data)
  }

  confirmProjectBatchInvitationWithNewOrganization(data: any) {
    return this.httpWithoutInterceptor.post(`${environment.urls.project.apiProjectBatchInvitation}confirm_invitation_with_new_org/`, data)
  }


  changeInvitationPreferredEmail(data: any) {
    return this.httpWithoutInterceptor.post(`${environment.urls.project.apiProjectBatchInvitation}change_preferred_email/`, data)
  }

  changeBatchInvitationPreferredEmail(data: any) {
    return this.httpWithoutInterceptor.post(`${environment.urls.project.apiProjectBatchInvitation}change_preferred_email/`, data)
  }


  copyProject(projectID: string | null, organizationID: string | null, data: any) {
    let params = new HttpParams();
    if (organizationID) {
      params = params.append('organization', `${organizationID}`)
    }
    return this.http.post(`${environment.urls.project.apiProjectURL}${projectID}/copy_project_to_org/`, data, {params: params})
  }

  syncProject(sourceID: string | null, targetID: string | null, data: any) {
    let params = new HttpParams();
    if (sourceID) {
      params = params.append('source', `${sourceID}`)
    }
    return this.http.post(`${environment.urls.project.apiProjectURL}${targetID}/sync_project_price_list/`, data, {params: params})

  }

  getProjects(page: number = 1, pageSize: number = 10, filters: any = null, isOnHub: boolean = false): Observable<ProjectListingResponse> {
    let params = new HttpParams();
    params = params.append('page', `${page}`)
    params = params.append('page_size', `${pageSize}`)
    if (!!filters) {
      if ('status' in filters && !!filters.status) {
        params = params.append('status', `${filters.status}`)
      }
      if ('isInvitable' in filters && filters.isInvitable !== null && filters.isInvitable !== undefined) {
        params = params.append('is_invitable', `${filters.isInvitable}`)
      }
      if ('isEstablished' in filters && filters.isEstablished !== null) {
        params = params.append('established', `${filters.isEstablished}`)
      }
      if ('suburb' in filters && !!filters.suburb) {
        if (Array.isArray(filters.suburb)) {
          for (let sub of filters.suburb) {
            params = params.append('suburb', `${sub}`)
          }
        } else {
          params = params.append('suburb', `${filters.suburb}`)
        }

      }
      if ('is_owner' in filters && filters.is_owner !== undefined && filters.is_owner !== null) {
        params = params.append('is_owner', `${filters.is_owner}`)
      }
      if ('owner' in filters && filters.owner !== undefined && filters.owner !== null) {
        params = params.append('owner', `${filters.owner}`)
      }
      if ('owner_only' in filters && filters.owner_only !== undefined && filters.owner_only !== null) {
        params = params.append('owner_only', `${filters.owner_only}`)
      }
      if ('accessibility' in filters && filters.accessibility !== undefined && filters.accessibility !== null) {
        params = params.append('is_authorized', `${filters.accessibility}`)
      }
      if ('agency' in filters && filters.agency !== undefined && filters.agency !== null) {
        params = params.append('agency', `${filters.agency}`)
      }
      if ('notAgency' in filters && filters.notAgency !== undefined && filters.notAgency !== null) {
        params = params.append('not_agency', `${filters.notAgency}`)
      }
      if ('organization' in filters && filters.organization !== undefined && filters.organization !== null) {
        params = params.append('owner', `${filters.organization}`)
      }
      if ('maintenance' in filters && !!filters.maintenance) {
        params = params.append('maintenance', `${filters.maintenance}`)
      }
      if ('search' in filters && !!filters.search) {
        params = params.append('search', `${filters.search}`)
      }
      if ('minPrice' in filters && !!filters.minPrice) {
        params = params.append('min_price', `${filters.minPrice}`)
      }
      if ('maxPrice' in filters && !!filters.maxPrice) {
        params = params.append('max_price', `${filters.maxPrice}`)
      }
      if ('projectNameSearch' in filters && !!filters.projectNameSearch) {
        params = params.append('name_search', `${filters.projectNameSearch}`)
      }
      if ('organizationID' in filters && !!filters.organizationID) {
        params = params.append('organization_id', `${filters.organizationID}`)
      }
      if ('type' in filters && !!filters.type) {
        params = params.append('type', `${filters.type}`)
      }
      if ('orderBy' in filters && !!filters.orderBy) {
        if (Array.isArray(filters.orderBy)) {
          for (let sub of filters.orderBy) {
            params = params.append('order_by', `${sub}`)
          }
        } else {
          params = params.append('order_by', `${filters.orderBy}`)
        }

      }
    }
    if (isOnHub) {
      params = params.append('on_hub', `True`)
    }
    return this.http.get(environment.urls.project.apiProjectURL, {params: params}).pipe(
      map((data: any) => {
        return {
          ...data,
          results: data.results.map((item: ResponseProjectListingItem): ProjectListingCard => {
            return {
              id: item.uuid,
              name: item.name,
              address: !!item.address ? item.address.formatted_address : '',
              suburb: !!item.address ? item.address.suburb : '',
              state: !!item.address ? item.address.state : '',
              postcode: !!item.address ? item.address.postcode : '',
              cover: item.cover,
              is_archived_internally: item.is_archived_internally,
              updatedDate: !!item.updated_at ? this.datePipe.transform(item.updated_at, 'dd-MM-yyyy') : '',
              status: item.status,
              isOwner: item.is_owner,
              isAuthorized: item.is_authorized,
              collectID: item.collectID,
              ownerType: item.owner_type,
              owner: item.owner,
              ownerName: item.organization_name,
              relationType: item.relation_type,
              isInvitable: item.is_invitable,
              developer: item.developer,
              builder: item.builder,
              type: item.type,
              price_list_maintain: item.price_list_maintain,
              has_training: item.has_training,
              is_established: item.is_established,
              extra_info: !!item.extra_info ? item.extra_info : null,
              low_maintain_url: item.low_maintain_url,
              low_updated_at: item.low_updated_at,
              isRecommended: item.is_recommended,
              has_price_index: item.has_price_index,
            }
          })
        }
      }),
      tap((re: any) => {
        this.projectCache = re.results;
      })
    )
  }

  getProjectExport() {
    return this.http.get(`${environment.urls.project.apiProjectListExport}`, {responseType: 'blob'});
  }

  getProjectUnitExport(projectID: string | null,) {
    return this.http.get(`${environment.urls.project.apiProjectURL}${projectID}/export_unit_list/`, {responseType: 'blob'});
  }

  getProjectConfig(projectID: string | null, name: string | null = null) {
    let params = new HttpParams();
    params = params.append('project', `${projectID}`)
    params = params.append('name', `${name}`)
    return this.http.get(environment.urls.project.apiProjectConfigURL, {params: params})
  }

  createProjectConfig(data: any) {
    return this.http.post(environment.urls.project.apiProjectConfigURL, data)
  }

  updateProjectConfig(configID: string | null, data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectConfigURL}${configID}/`, data)
  }

  updateProjectRecommendation(projectID: string | null, data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectURL}${projectID}/change_recommendation_status/`, data)
  }

  updateProjectStatus(projectID: string | null, data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectURL}${projectID}/change_archive_status/`, data)
  }

  getHubProjects(page: number = 1, pageSize: number = 10, filters: any = null,): Observable<ProjectHubListingResponse> {
    let params = new HttpParams();
    params = params.append('page', `${page}`)
    params = params.append('page_size', `${pageSize}`)
    if (!!filters) {
      if ('status' in filters && !!filters.status) {
        params = params.append('status', `${filters.status}`)
      }
      if ('isEstablished' in filters && !!filters.isEstablished) {
        params = params.append('established', `${filters.isEstablished}`)
      }
      if ('suburb' in filters && !!filters.suburb) {
        params = params.append('suburb', `${filters.suburb}`)
      }
      if ('maintenance' in filters && !!filters.maintenance) {
        params = params.append('maintenance', `${filters.maintenance}`)
      }
      if ('search' in filters && !!filters.search) {
        params = params.append('search', `${filters.search}`)
      }
      if ('minPrice' in filters && !!filters.minPrice) {
        params = params.append('min_price', `${filters.minPrice}`)
      }
      if ('maxPrice' in filters && !!filters.maxPrice) {
        params = params.append('max_price', `${filters.maxPrice}`)
      }
    }
    return this.http.get(environment.urls.project.apiProjectURL, {params: params}).pipe(map((data: any) => {
      return {
        ...data,
        results: data.results.map((item: ResponseHubProjectListingItem): HubProjectListingCard => {
          return {
            id: item.uuid,
            name: item.name,
            address: !!item.address ? item.address.formatted_address : '',
            cover: item.cover,
            ownerType: item.owner_type,
            applicationStatus: item.application_status,
            rejectedDate: !!item.rejected_at ? this.datePipe.transform(item.rejected_at, 'dd-MM-yyyy') : '',
          }
        })
      }
    }))
  }

  getProjectCache() {
    return this.projectCache;
  }

  getProjectSuburbCensus(suburb: string | null) {
    let params = new HttpParams();
    params = params.append('suburb', `${suburb}`);
    return this.http.get(`${environment.urls.project.apiProjectSuburbCensus}get_by_suburb_name/`, {params: params})
  }

  getProjectSchoolInformation(projectID: string | null, type: string) {
    let params = new HttpParams();
    params = params.append('project', `${projectID}`);
    params = params.append('school_type', `${type}`);
    return this.http.get(`${environment.urls.project.apiProjectSchoolInfo}`, {params: params}).pipe(map((x: any) => {
      return {
        ...x,
        results: x.results.map((item: any) => {
          return {
            ...item,
            position: {
              lat: item.latitude,
              lng: item.longitude
            }
          }
        })
      }
    }))

  }

  getUnitTags(search: string | null = null) {
    let params = new HttpParams();
    if (search) {
      params = params.append('search', `${search}`);
    }
    return this.http.get(`${environment.urls.project.apiUnitTag}`, {params: params})
  }

  getUnitRelationTags(unitID: string | null) {
    let params = new HttpParams();
    if (unitID) {
      params = params.append('unit', `${unitID}`);
    }
    return this.http.get(`${environment.urls.project.apiUnitTagRelation}`, {params: params})
  }

  bulkUnitTagRelation(unitID: string | null, data: any) {
    return this.http.post(`${environment.urls.project.apiUnitTagRelation}bulk_create/`, data)
  }

  deleteUnitTagRelation(tagID: string | null) {
    return this.http.delete(`${environment.urls.project.apiUnitTagRelation}${tagID}/`)
  }

  collectProject(payload: object) {
    return this.http.post(environment.urls.project.apiProjectCollectURL, payload)
  }

  deleteCollectedProject(id: number) {
    return this.http.delete(`${environment.urls.project.apiProjectCollectURL}${id}/`)
  }

  createProjectDescription(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectDescription}`, data)
  }

  updateProjectDescription(descriptionID: string, data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectDescription}${descriptionID}/`, data)
  }

  getProjectDescriptionDetail(projectID: string | null, language: string | null = null) {
    let params = new HttpParams();
    params = params.append('project', `${projectID}`);
    if (language) {
      params = params.append('language_code', `${language}`);
    }
    return this.http.get(`${environment.urls.project.apiProjectDescription}`, {params: params});
  }

  createProjectRequest(payload: object) {
    return this.http.post(environment.urls.project.apiProjectApplicationURL, payload)
  }

  createUnit(projectID: string | null, payload: object) {
    return this.http.post(`${environment.urls.project.apiProjectUnitURL}`, payload)
  }

  updateUnit(unitID: string | null, payload: object) {
    return this.http.patch(`${environment.urls.project.apiProjectUnitURL}${unitID}/`, payload)
  }

  deleteUnit(unitID: string | null) {
    return this.http.delete(`${environment.urls.project.apiProjectUnitURL}${unitID}/`)
  }

  checkUnitExists(payload: object) {
    return this.http.post(`${environment.urls.project.apiProjectUnitURL}check_exists/`, payload)
  }

  getProjectMediaImages(projectID: string | null) {
    let params = new HttpParams();
    params = params.append('project', `${projectID}`)
    return this.http.get(`${environment.urls.project.apiProjectImage}`, {params: params})
  }

  updateProjectMediaImageOrder(data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectImage}order_update/`, data)
  }

  uploadMediaImage(projectID: string | null, key: string, formData: FormData) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.put(`${environment.urls.project.apiProjectSpecialURL}${projectID}/image/`, formData, {params: params})
  }

  deleteMediaImage(pk: string) {
    return this.http.delete(`${environment.urls.project.apiProjectImage}${pk}/`)
  }

  deleteMediaVideo(pk: string) {
    return this.http.delete(`${environment.urls.project.apiProjectVideo}${pk}/`)
  }

  deleteUploadMediaImage(projectID: string | null, key: string,) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.delete(`${environment.urls.project.apiProjectSpecialURL}${projectID}/image/`, {params: params})
  }

  createMediaImage(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectImage}`, data)
  }

  getProjectMediaVideos(projectID: string | null) {
    let params = new HttpParams();
    params = params.append('project', `${projectID}`)
    return this.http.get(`${environment.urls.project.apiProjectVideo}`, {params: params})
  }

  uploadMediaVideo(projectID: string | null, key: string, formData: FormData) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.put(`${environment.urls.project.apiProjectSpecialURL}${projectID}/video/`, formData, {params: params})
  }

  deleteUploadMediaVideo(projectID: string | null, key: string,) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.delete(`${environment.urls.project.apiProjectSpecialURL}${projectID}/video/`, {params: params})
  }

  createMediaVideo(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectVideo}`, data)
  }

  getProjectVR(projectID: string | null) {
    let params = new HttpParams();
    params = params.append('project', `${projectID}`)
    return this.http.get(`${environment.urls.project.apiProjectVR}`, {params: params})
  }

  createProjectVR(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectVR}`, data)
  }

  updateProjectVR(id: string | null = null, data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectVR}${id}/`, data)
  }

  deleteProjectVR(id: any) {
    return this.http.delete(`${environment.urls.project.apiProjectVR}${id}/`)
  }

  deleteProjectVRCover(id: any, url: string) {
    let params = new HttpParams();
    params = params.append('url', `${url}`)
    return this.http.delete(`${environment.urls.project.apiProjectVR}${id}/delete_current_cover/`, {params: params})
  }

  getFloorPlan(projectID: string | null) {
    let params = new HttpParams();
    params = params.append('project', `${projectID}`)
    return this.http.get(`${environment.urls.project.apiProjectFloorPlan}`, {params: params})
  }

  getFloorPlanImages(floorPlanID: string | null) {
    let params = new HttpParams();
    params = params.append('floor_plan', `${floorPlanID}`)
    return this.http.get(`${environment.urls.project.apiProjectFloorPlanImage}`, {params: params})
  }


  getFloorPlanDetail(floorPlanID: string | null) {
    return this.http.get(`${environment.urls.project.apiProjectFloorPlan}${floorPlanID}/`,)
  }

  getLevelPlan(projectID: string | null, filters: any = null) {
    let params = new HttpParams();
    params = params.append('project', `${projectID}`)
    if (!!filters) {
      if ('building' in filters && !!filters.building) {
        params = params.append('building', `${filters.building}`)
      }
    }
    return this.http.get(`${environment.urls.project.apiProjectLevel}`, {params: params})
  }

  getLevelPlanDetail(levelID: string | null) {
    return this.http.get(`${environment.urls.project.apiProjectLevel}${levelID}/`)
  }

  uploadLevelPlanImage(projectID: string | null, filename: string, formData: FormData) {
    return this.http.post(`${environment.urls.project.apiProjectMedia}${projectID}/images/level_plan/${filename}/`, formData)
  }

  levelConnectUnits(levelID: string | null, data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectLevel}${levelID}/bulk_connect_units/`, data)
  }

  floorPlanConnectUnits(floorPlanID: string | null, data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectFloorPlan}${floorPlanID}/bulk_connect_units/`, data)
  }


  getLevelPlanImages(levelID: string | null) {
    let params = new HttpParams();
    params = params.append('level', `${levelID}`)
    return this.http.get(`${environment.urls.project.apiProjectLevelPlanImage}`, {params: params})
  }

  updateLevelPlanImagesOrder(data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectLevelPlanImage}order_update/`, data)
  }

  deleteLevelPlanImageRelation(levelID: string | null) {
    return this.http.delete(`${environment.urls.project.apiProjectLevelPlanImage}${levelID}/`)
  }

  deleteFloorPlanImageRelation(floorPlanID: string | null) {
    return this.http.delete(`${environment.urls.project.apiProjectFloorPlanImage}${floorPlanID}/`)
  }

  deleteLevelPlanImage(projectID: string | null, filename: string) {
    return this.http.delete(`${environment.urls.project.apiProjectMedia}${projectID}/images/level_plan/${filename}/`)
  }

  deleteFloorPlanImage(projectID: string | null, filename: string) {
    return this.http.delete(`${environment.urls.project.apiProjectMedia}${projectID}/images/floor_plan/${filename}/`)
  }

  uploadFloorPlanImage(projectID: string | null, filename: string, formData: FormData) {
    return this.http.post(`${environment.urls.project.apiProjectMedia}${projectID}/images/floor_plan/${filename}/`, formData)
  }

  createLevelPlanImageRelation(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectLevelPlanImage}`, data)
  }

  createFloorPlanImageRelation(projectID: string | null, data: any) {
    return this.http.post(`${environment.urls.project.apiProjectFloorPlanImage}`, data)
  }

  updateFloorPlanImagesOrder(data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectFloorPlanImage}order_update/`, data)
  }


  createLevel(name: string, projectID: string | null, building_name: string, url: string, absUrl: string) {
    return this.http.post(`${environment.urls.project.apiProjectLevel}`, {
      name: name,
      project: projectID,
      building_name: building_name,
      url: url,
      abs_url: absUrl
    })
  }

  createLevelPlan(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectLevel}`, data)
  }

  checkLevelExists(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectLevel}check_exists/`, data)
  }

  updateLevelPlan(levelID: string | null, data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectLevel}${levelID}/`, data)
  }

  deleteLevelPlan(levelID: string | null) {
    return this.http.delete(`${environment.urls.project.apiProjectLevel}${levelID}/`)
  }

  createBuilding(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectBuilding}`, data)
  }

  checkBuildingExists(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectBuilding}check_exists/`, data)
  }

  updateBuilding(buildingID: string | null, data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectBuilding}${buildingID}/`, data);
  }

  deleteBuilding(buildingID: string | null) {
    return this.http.delete(`${environment.urls.project.apiProjectBuilding}${buildingID}/`);
  }

  createFloorPlan(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectFloorPlan}`, {
      ...data
    })
  }

  checkFloorPlanExists(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectFloorPlan}check_exists/`, data);
  }

  updateFloorPlan(floorPlanID: string | null, data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectFloorPlan}${floorPlanID}/`, data)
  }

  deleteFloorPlan(floorPlanID: string | null) {
    return this.http.delete(`${environment.urls.project.apiProjectFloorPlan}${floorPlanID}/`)
  }

  consumeCacheData(cacheID: string) {
    return this.http.post(`${environment.urls.project.apiCache}${cacheID}/partial_consume/`, {})
  }

  unitBulkCreate(projectID: string | null, data: any) {
    return this.http.post(`${environment.urls.project.apiProjectSpecialURL}${projectID}/unit_bulk/`, data)
  }

  getCachedUnits(cacheID: string) {
    let params = new HttpParams();
    params = params.append('cache_id', cacheID);
    return this.http.get(`${environment.urls.project.apiUnitCache}`, {params: params})
  }

  updateCacheUnit(id: string | null, data: any) {
    return this.http.patch(`${environment.urls.project.apiUnitCache}${id}/`, data)
  }

  deleteCachedUnit(id: string | null) {
    return this.http.delete(`${environment.urls.project.apiUnitCache}${id}/`)
  }

  createProject(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectURL}`, data)
  }

  updateProject(id: string | null, data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectURL}${id}/`, data)
  }

  deleteProject(id: string | null) {
    return this.http.delete(`${environment.urls.project.apiProjectURL}${id}/`,)
  }

  getProjectProfile(id: string | null) {
    return this.http.get(`${environment.urls.project.apiProjectURL}${id}/`)
  }

  getProjectPriceRange(id: string | null) {
    return this.http.get(`${environment.urls.project.apiProjectURL}${id}/get_price_range/`)
  }

  getProjectLatestPromotion(id: string | null) {
    return this.http.get(`${environment.urls.project.apiProjectURL}${id}/latest_promotion_info/`)
  }

  getProjectOptions(id: string | null, filters: any) {
    let params = new HttpParams();
    if (!!filters) {
      if ('level' in filters && !!filters.level) {
        params = params.append('level', `${filters.level}`)
      }
      if ('floor_plan' in filters && !!filters.floor_plan) {
        params = params.append('floor_plan', `${filters.floor_plan}`)
      }
    }
    return this.http.get(`${environment.urls.project.apiProjectURL}${id}/get_options/`, {params: params})
  }

  listInternalResourceFolder(projectID: string | null, key: string, p: boolean = false) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    if (p) {
      params = params.append('private', `${p}`)
    }
    return this.http.get(`${environment.urls.project.apiInternalProjectURL}${projectID}/resource/folder/`, {params: params})
  }

  createInternalResourceFolder(projectID: string | null, key: string, p: boolean = false) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    if (p) {
      params = params.append('private', `${p}`);
    }
    return this.http.put(`${environment.urls.project.apiInternalProjectURL}${projectID}/resource/folder/`, {}, {params: params})
  }

  deleteInternalResourceFolder(projectID: string | null, key: string, p: boolean = false) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    if (p) {
      params = params.append('private', `${p}`)
    }
    return this.http.delete(`${environment.urls.project.apiInternalProjectURL}${projectID}/resource/folder/`, {params: params})
  }

  deleteInternalResourceFolders(projectID: string | null, keys: string[], p: boolean = false) {
    let params = new HttpParams();
    for (let key of keys) {
      params = params.append('key', `${key}`)
    }
    if (p) {
      params = params.append('private', `${p}`)
    }
    return this.http.delete(`${environment.urls.project.apiInternalProjectURL}${projectID}/resource/folder/`, {params: params})
  }

  uploadInternalResourceFile(projectID: string | null, key: string, formData: FormData, p: boolean = false) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    if (p) {
      params = params.append('private', `${p}`)
    }
    return this.http.put(`${environment.urls.project.apiInternalProjectURL}${projectID}/resource/file/`, formData, {params: params})
  }

  createOrGetPriceListShareFile(projectID: string | null, formData: any, isPrivateSpace: boolean = false) {
    let params = new HttpParams();
    if (isPrivateSpace) {
      params = params.append('private', `${isPrivateSpace}`)
    }
    return this.http.post(`${environment.urls.project.apiInternalProjectURL}${projectID}/price_list/share/`, formData, {params: params})
  }

  createOrGetInternalShareFile(projectID: string | null, formData: any, isPrivateSpace: boolean = false) {
    let params = new HttpParams();
    if (isPrivateSpace) {
      params = params.append('private', `${isPrivateSpace}`)
    }
    return this.http.post(`${environment.urls.project.apiInternalProjectURL}${projectID}/resource/file/share/`, formData, {params: params})
  }

  createOrGetProjectMediaLink(projectID: string | null, formData: any) {
    let params = new HttpParams();
    return this.http.post(`${environment.urls.project.apiProjectURL}${projectID}/media-share-link/`, formData, {params: params})
  }

  getInternalResourceFile(projectID: string | null, key: string, blob: boolean = true, p: boolean = false) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    if (p) {
      params = params.append('private', `${p}`);
    }
    if (blob) {
      return this.http.get(`${environment.urls.project.apiInternalProjectURL}${projectID}/resource/file/`, {
        params: params,
        responseType: 'blob'
      })
    } else {
      return this.http.get(`${environment.urls.project.apiInternalProjectURL}${projectID}/resource/file/`, {
        params: params,
      })
    }

  }

  getInternalResourceFileHeader(projectID: string | null, key: string, p: boolean = false) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    if (p) {
      params = params.append('private', `${p}`)
    }
    return this.http.get(`${environment.urls.project.apiInternalProjectURL}${projectID}/resource/meta/`, {params: params})
  }

  deleteInternalResourceFile(projectID: string | null, key: string, p: boolean = false) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    if (p) {
      params = params.append('private', `${p}`)
    }
    return this.http.delete(`${environment.urls.project.apiInternalProjectURL}${projectID}/resource/file/`, {params: params})
  }

  deleteInternalResourceFiles(projectID: string | null, keys: string[], p: boolean = false) {
    let params = new HttpParams();
    for (let key of keys) {
      params = params.append('key', `${key}`)
    }
    if (p) {
      params = params.append('private', `${p}`)
    }
    return this.http.delete(`${environment.urls.project.apiInternalProjectURL}${projectID}/resource/file/`, {params: params})
  }

  listExternalResourceFolder(projectID: string | null, key: string,) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.get(`${environment.urls.project.apiProjectURL}${projectID}/resource/folder/`, {params: params})
  }

  createExternalResourceFolder(projectID: string | null, key: string) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.put(`${environment.urls.project.apiProjectURL}${projectID}/resource/folder/`, {}, {params: params})
  }

  deleteExternalResourceFolder(projectID: string | null, key: string) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.delete(`${environment.urls.project.apiProjectURL}${projectID}/resource/folder/`, {params: params})
  }

  deleteExternalResourceFolders(projectID: string | null, keys: string[]) {
    let params = new HttpParams();
    for (let key of keys) {
      params = params.append('key', `${key}`)
    }
    return this.http.delete(`${environment.urls.project.apiProjectURL}${projectID}/resource/folder/`, {params: params})
  }

  uploadExternalResourceFile(projectID: string | null, key: string, formData: FormData) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.put(`${environment.urls.project.apiProjectURL}${projectID}/resource/file/`, formData, {params: params})
  }

  getExternalResourceFile(projectID: string | null, key: string) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.get(`${environment.urls.project.apiProjectURL}${projectID}/resource/file/`)
  }

  deleteExternalResourceFile(projectID: string | null, key: string,) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.delete(`${environment.urls.project.apiProjectURL}${projectID}/resource/file/`)
  }

  deleteExternalResourceFiles(projectID: string | null, keys: string[],) {
    let params = new HttpParams();
    for (let key of keys) {
      params = params.append('key', `${key}`)
    }
    return this.http.delete(`${environment.urls.project.apiProjectURL}${projectID}/resource/file/`)
  }

  listInternalShareImageFolder(projectID: string | null, key: string,) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.get(`${environment.urls.project.apiInternalProjectURL}${projectID}/share/images/folder/`, {params: params})
  }

  uploadInternalLowMaintainFile(projectID: string | null, key: string, formData: FormData, p: boolean = false) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    if (p) {
      params = params.append('private', `${p}`)
    }
    return this.http.put(`${environment.urls.project.apiInternalProjectURL}${projectID}/low_maintain_files/`, formData, {params: params})
  }

  deleteInternalLowMaintainFile(url: string, p: boolean = false) {
    let params = new HttpParams();
    if (p) {
      params = params.append('private', `${p}`)
    }
    return this.http.delete(`${url}`, {params: params})
  }

  getLowMaintainFileURL(url: string, p: boolean = false) {
    let params = new HttpParams();
    if (p) {
      params = params.append('private', `${p}`)
    }
    return this.http.get(`${url}`, {params: params});
  }

  uploadInternalShareImageFile(projectID: string | null, key: string, formData: FormData) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.put(`${environment.urls.project.apiProjectSpecialURL}${projectID}/images/`, formData, {params: params})
  }

  getInternalCourseFile(url: string, p: boolean = false) {
    let params = new HttpParams();
    if (p) {
      params = params.append('private', `${p}`)
    }
    return this.http.get(url, {params: params});
  }

  uploadInternalCourseFile(projectID: string | null, key: string, formData: FormData, p: boolean = false) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    if (p) {
      params = params.append('private', `${p}`)
    }
    return this.http.put(`${environment.urls.project.apiInternalProjectURL}${projectID}/courses/`, formData, {params: params})
  }

  deleteInternalCourseFile(projectID: string | null, key: string, p: boolean = false) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    if (p) {
      params = params.append('private', `${p}`)
    }
    return this.http.delete(`${environment.urls.project.apiInternalProjectURL}${projectID}/courses/`, {params: params})
  }

  uploadCourseCoverImage(projectID: string | null, key: string, formData: FormData) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.put(`${environment.urls.project.apiInternalProjectURL}${projectID}/covers/`, formData, {params: params})
  }

  uploadInternalShareVideoFile(projectID: string | null, key: string, formData: FormData) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.put(`${environment.urls.project.apiProjectSpecialURL}${projectID}/videos/`, formData, {params: params})
  }

  createInternalShareImageFolder(projectID: string | null, key: string) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.put(`${environment.urls.project.apiInternalProjectURL}${projectID}/share/images/folder/`, {}, {params: params})
  }

  getInternalShareImageFile(projectID: string | null, key: string) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.get(`${environment.urls.project.apiInternalProjectURL}${projectID}/share/images/file/`, {params: params})
  }

  deleteInternalShareImageFile(projectID: string | null, key: string,) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.delete(`${environment.urls.project.apiInternalProjectURL}${projectID}/share/images/file/`, {params: params})
  }

  deleteInternalShareImageFiles(projectID: string | null, keys: string[],) {
    let params = new HttpParams();
    for (let key of keys) {
      params = params.append('key', `${key}`)
    }
    return this.http.delete(`${environment.urls.project.apiInternalProjectURL}${projectID}/share/images/file/`, {params: params})
  }

  deleteInternalShareImageFolder(projectID: string | null, key: string) {
    let params = new HttpParams();
    params = params.append('key', `${key}`)
    return this.http.delete(`${environment.urls.project.apiInternalProjectURL}${projectID}/share/images/folder/`, {params: params})
  }

  getProjectCourses(projectID: string | null) {
    let params = new HttpParams();
    params = params.append('project', `${projectID}`)
    return this.http.get(`${environment.urls.project.apiProjectCourse}`, {params: params})
  }

  getProjectCourse(courseID: string | null) {
    return this.http.get(`${environment.urls.project.apiProjectCourse}${courseID}/`)
  }

  getProjectCourseQA(courseID: string | null) {
    let params = new HttpParams();
    params = params.append('course', `${courseID}`)
    return this.http.get(`${environment.urls.project.apiProjectCourseQA}`, {params: params})
  }

  createProjectCourse(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectCourse}`, data)
  }

  updateProjectCourse(courseID: string | null, data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectCourse}${courseID}/`, data)
  }

  deleteProjectCourse(courseID: string | null) {
    return this.http.delete(`${environment.urls.project.apiProjectCourse}${courseID}/`)
  }

  deleteProjectCourseQA(courseID: string | null) {
    return this.http.delete(`${environment.urls.project.apiProjectCourseQA}${courseID}/`)
  }

  bulkCreateProjectCourseQA(data: any[]) {
    return this.http.post(`${environment.urls.project.apiProjectCourseQA}`, data)
  }

  bulkUpdateProjectCourseQA(data: any[]) {
    return this.http.patch(`${environment.urls.project.apiProjectCourseQA}bulk_update_or_create/`, data)
  }

  getProjectNews(projectID: string | null, filters: any = null) {
    let params = new HttpParams();
    params = params.append('project', `${projectID}`)
    if (!!filters) {
      if ('search' in filters && !!filters.search) {
        params = params.append('search', `${filters.search}`)
      }
      if ('start_date' in filters && !!filters.start_date) {
        params = params.append('start_date', `${this.datePipe.transform(filters.start_date, 'yyyy-MM-dd')}`)
      }
      if ('end_date' in filters && !!filters.end_date) {
        params = params.append('end_date', `${this.datePipe.transform(filters.end_date, 'yyyy-MM-dd')}`)
      }
    }
    return this.http.get(`${environment.urls.project.apiProjectNews}`, {params: params})
  }

  createProjectNews(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectNews}`, data)
  }

  getProjectPromotion(projectID: string | null, filters: any = null) {
    let params = new HttpParams();
    params = params.append('project', `${projectID}`)
    if (!!filters) {
      if ('search' in filters && !!filters.search) {
        params = params.append('search', `${filters.search}`)
      }
      if ('type' in filters && !!filters.search) {
        params = params.append('type', `${filters.type}`)
      }
      if ('status' in filters && !!filters.status) {
        params = params.append('status', `${filters.status}`)
      }
      if ('start_date' in filters && !!filters.start_date) {
        params = params.append('start_date', `${this.datePipe.transform(filters.start_date, 'yyyy-MM-dd')}`)
      }
      if ('end_date' in filters && !!filters.end_date) {
        params = params.append('end_date', `${this.datePipe.transform(filters.end_date, 'yyyy-MM-dd')}`)
      }
    }
    return this.http.get(`${environment.urls.project.apiProjectPromotion}`, {params: params})
  }

  createPromotion(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectPromotion}`, data)
  }

  createPromotionUnitRelation(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectPromotionUnitRelation}`, data)
  }

  getProjectUnits(projectID: string | null, page: number = 1, pageSize: number = 10, filters: any = null) {
    let params = new HttpParams();
    params = params.append('project', `${projectID}`);
    params = params.append('page', `${page}`);
    params = params.append('page_size', `${pageSize}`);
    if (filters) {
      if ('bedroom' in filters && filters.bedroom !== null && filters.bedroom !== undefined) {
        params = params.append('bedroom', `${filters.bedroom}`);
      }
      if ('bathroom' in filters && filters.bathroom !== null && filters.bathroom !== undefined) {
        params = params.append('bathroom', `${filters.bathroom}`);
      }
      if ('level' in filters && !!filters.level) {
        params = params.append('level', `${filters.level}`);
      }
      if ('level_name' in filters && !!filters.level_name) {
        params = params.append('level_name', `${filters.level_name}`);
      }
      if ('floor_plan' in filters && !!filters.floor_plan) {
        params = params.append('floor_plan', `${filters.floor_plan}`);
      }
      if ('building' in filters && !!filters.building) {
        params = params.append('building', `${filters.building}`);
      }
      if ('search' in filters && !!filters.search) {
        params = params.append('search', `${filters.search}`);
      }
      if ('status' in filters && !!filters.status) {
        params = params.append('status', `${filters.status}`);
      }
      if ('carPark' in filters && filters.carPark !== null && filters.carPark !== undefined) {
        params = params.append('car_park', `${filters.carPark}`);
      }
      if ('aspect' in filters && !!filters.aspect) {
        params = params.append('aspect', `${filters.aspect}`);
      }
      if ('minPrice' in filters && !!filters.minPrice) {
        params = params.append('min_price', `${filters.minPrice}`);
      }
      if ('maxPrice' in filters && !!filters.maxPrice) {
        params = params.append('max_price', `${filters.maxPrice}`);
      }
      if ('connectType' in filters && !!filters.connectType) {
        params = params.append('connect_type', `${filters.connectType}`);
      }
      if ('target' in filters && !!filters.target) {
        params = params.append('target', `${filters.target}`);
      }
    }
    return this.http.get(`${environment.urls.project.apiProjectUnit}`, {params: params});
  }

  getBuildings(projectID: string | null) {
    let params = new HttpParams();
    params = params.append('project', `${projectID}`);
    return this.http.get(`${environment.urls.project.apiProjectBuilding}`, {params: params}).pipe(map((x: any) => {
      return x.map((item: any) => {
        return {
          id: item.uuid,
          name: item.name,
          completionDate: item.completion_date,
        }
      })
    }));
  }

  getUnitDetail(unitID: string | null) {
    return this.http.get(`${environment.urls.project.apiProjectUnit}${unitID}/`)
  }

  getLowMaintainPriceRange(projectID: string | null) {
    let params = new HttpParams();
    params = params.append('project', `${projectID}`);
    return this.http.get(`${environment.urls.project.apiProjectPriceIndex}`, {params: params})
  }

  bulkCreateAndUpdateMaintainPriceRange(data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectPriceIndex}bulk_update_or_create/`, data)
  }

  deletePriceRangeIndex(id: string) {
    return this.http.delete(`${environment.urls.project.apiProjectPriceIndex}${id}/`);
  }

  getPdfImages(url: string) {
    return this.http.post(`${environment.urls.service.pdfImages}`, {url: url})
  }

  getProposals(page: any, pageSize: any, filters: any) {
    let params = new HttpParams();
    params = params.append('page', `${page}`);
    params = params.append('page_size', `${pageSize}`);
    if (filters) {
      if ('project' in filters && filters.project !== null && filters.project !== undefined) {
        params = params.append('project', `${filters.project}`);
      }
    }
    return this.http.get(`${environment.urls.project.apiProjectProposal}`, {params: params});
  }

  getProposalDetail(id: string | null) {
    return this.http.get(`${environment.urls.project.apiProjectProposal}${id}/`);
  }

  createProposal(data: any) {
    return this.http.post(`${environment.urls.project.apiProjectProposal}`, data);
  }

  aiRewriteDesc(id: string | null, conversation: any[]) {
    return this.http.post(`${environment.urls.project.apiProjectURL}${id}/ai-rewrite-desc/`, {
      conversation: conversation
    });
  }

  updateProposal(id: string | null, data: any) {
    return this.http.patch(`${environment.urls.project.apiProjectProposal}${id}/`, data);
  }

  deleteProposal(id: string | null) {
    return this.http.delete(`${environment.urls.project.apiProjectProposal}${id}/`);
  }

  deleteProposalFloorPlanFile(id: string | null, key: any) {
    let params = new HttpParams();
    params = params.append('key', `${key}`);
    return this.http.delete(`${environment.urls.project.apiProjectProposal}${id}/delete_floor_plan_file/`,
      {params: params});
  }

  bulkCreateProjectLevelPlan(id: string | null, data: any) {
    return this.http.post(`${environment.urls.project.apiProjectURL}${id}/bulk_create_level_plan/`, data);
  }

  bulkCreateProjectFloorPlan(id: string | null, data: any) {
    return this.http.post(`${environment.urls.project.apiProjectURL}${id}/bulk_create_floor_plan/`, data);
  }


  // getResizedImage(bucket: string, size: string) {
  //   return
  // }


}
