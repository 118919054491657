<div class="layout-container">
  <mat-progress-bar *ngIf="showRouterNavigation" class="nav-loading-progress-bar"
                    mode="indeterminate"></mat-progress-bar>
  <div class="header-section">
    <div class="header-wrap">
      <div class="mobile-nav">
        <a [ngStyle]="device !== 'web' ? {'display':'block'} : {}" mat-flat-button color="primary"
           [routerLink]="'project/list'"
           class="only-mobile mobile-menu">
          <div class="small-icon-button">
            <div class="small-icon-button-container">
              <div class="small-icon-button-icon-container">
                <img class="small-icon-button-img" src="assets/icons/realtycms_logo.svg" alt="realty-icon">
              </div>
            </div>
            <div style="margin-left: 18px; height: 38px; display: flex; align-items: center;">
              <div class="small-icon-button-text">
                Home
              </div>
            </div>
          </div>
        </a>
        <a [ngStyle]="device !== 'web' ? {'display':'block'} : {}" mat-flat-button color="primary"
           class="only-mobile mobile-menu"
           (click)="clickQuickSearch()">
          <div class="small-icon-button">
            <div class="small-icon-button-container">
              <div class="small-icon-button-icon-container">
                <mat-icon class="small-icon-button-icon">
                  map
                </mat-icon>
              </div>
            </div>
            <div style="margin-left: 18px; height: 38px; display: flex; align-items: center;">
              <div class="small-icon-button-text">
                Search
              </div>
            </div>
          </div>
        </a>
        <a [ngStyle]="device !== 'web' ? {'display':'block'} : {}" mat-flat-button color="primary"
           class="only-mobile mobile-menu"
           (click)="clickMessage()">
          <div class="small-icon-button">
            <div class="small-icon-button-container">
              <div class="small-icon-button-icon-container">
                <mat-icon class="small-icon-button-icon">
                  mail
                </mat-icon>
              </div>
            </div>
            <div style="margin-left: 18px; height: 38px; display: flex; align-items: center;">
              <div class="small-icon-button-text">
                Message
              </div>
            </div>
          </div>
        </a>

        <a *ngIf="device === 'web'" class="header-icon" mat-button [routerLink]="'project/list'">
          <img src="assets/icons/realtycms_logo.svg" alt="realty-icon">
        </a>
        <a *ngIf="device === 'web'" class="quick-search-icon" mat-button>
          <div class="quick-search-link" (click)="clickQuickSearch()">
            <mat-icon aria-hidden="false" aria-label="search icon">search</mat-icon>
            <div class="global-button">Quick Search</div>
          </div>
        </a>
      </div>
      <div *ngIf="device === 'web'" class="nav-bar-section">
        <ul class="nav-bar">
          <!--        <li><a mat-button [routerLink]="'/dashboard'" i18n>Dashboard</a></li>-->
          <li><a mat-button [routerLink]="'/project/list'" i18n>Projects</a></li>
          <li><a mat-button *ngIf="perms['user_management']" [routerLink]="'/manage/organizations'" i18n>Agencies</a>
          </li>
          <li><a mat-button [routerLink]="'/notification/received'" i18n>Messages</a></li>
          <!--        <li><a mat-button [routerLink]="'/property'" i18n>Properties</a></li>-->
          <!--        <li>-->
          <!--          <button mat-button [matMenuTriggerFor]="marketingMenu" [routerLink]="'/marketing'" i18n>Marketing</button>-->
          <!--          <mat-menu #marketingMenu="matMenu" yPosition="below">-->
          <!--            <button mat-menu-item>Marketing Library</button>-->
          <!--            <button mat-menu-item>News and Promotion</button>-->
          <!--            <button mat-menu-item>My Site</button>-->
          <!--          </mat-menu>-->
          <!--        </li>-->
          <!--        <li><a mat-button [routerLink]="'/enquiry'" i18n>Enquiries</a></li>-->
        </ul>
      </div>
      <div class="user-section">
        <!--      <a mat-button class="message-icon">-->
        <!--        <mat-icon aria-hidden="false" aria-label="search icon">notifications</mat-icon>-->
        <!--      </a>-->
        <!--      <a mat-button class="search-icon" (click)="sideNavClick(true)">-->
        <!--        <mat-icon aria-hidden="false" aria-label="search icon">menu</mat-icon>-->
        <!--      </a>-->
        <button mat-button [matMenuTriggerFor]="avatarMenu">
          <img *ngIf="!!userInfo.avatar" class="user-avatar" [src]="userInfo.avatar" alt="user-avatar">
          <img *ngIf="!!!userInfo.avatar" class="user-avatar" src="assets/icons/default-avatar.svg" alt="user-avatar">
        </button>
        <mat-menu #avatarMenu="matMenu" [class]="'user-menu'" xPosition="before">
          <div class="user-info">
            <div class="user-avatar">
              <div class="avatar">
                <img *ngIf="!!userInfo.avatar" [src]="userInfo.avatar" alt="realty-icon">
                <img *ngIf="!!!userInfo.avatar" src="assets/icons/default-avatar.svg">
              </div>

            </div>
            <div class="user-name body-1">{{userInfo.firstName|titlecase}} {{userInfo.lastName|titlecase}}</div>
            <div class="user-email body-2">{{userInfo.email}}</div>
            <div class="user-manage-btn global-button" mat-button (click)="goManageMyAccount()">
              Manage my account
            </div>
          </div>
          <div class="action-list">
            <button mat-menu-item (click)="goManageMyCompany()">My Company</button>
            <button mat-menu-item *ngIf="perms['user_management']" (click)="goToUserManagement()">User Management
            </button>
            <button mat-menu-item *ngIf="userInfo.email === platformAdmin" (click)="goToProjectCopy()">Copy
              Projects
            </button>
            <button mat-menu-item *ngIf="userInfo.email === platformAdmin" (click)="goToProjectSync()">Sync
              Projects
            </button>
            <button mat-menu-item (click)="openHelpCenter()">Help Center</button>
            <!--          <button mat-menu-item>Package Management</button>-->
            <!--          <button mat-menu-item>Help Center</button>-->
          </div>
          <div class="logout-section">
            <button mat-flat-button class="global-button logout-btn" (click)="logout()">Log Out</button>
          </div>
        </mat-menu>
      </div>
    </div>
    <!--    <div>-->
    <!--      <mat-form-field appearance="outline">-->
    <!--        <mat-label>Language</mat-label>-->
    <!--        <mat-select [ngModel]="language" (ngModelChange)="onChanges($event)">-->
    <!--          <mat-option *ngFor="let lang of languages" [value]="lang.language_code">{{lang.name}}</mat-option>-->
    <!--        </mat-select>-->
    <!--      </mat-form-field>-->
    <!--    </div>-->

  </div>
  <div class="breadcrumb-section" *ngIf="breadCrumb">
    <app-breadcrumb></app-breadcrumb>
  </div>
  <div class="content-section">
    <router-outlet></router-outlet>
  </div>
  <div class="sidenav-wrap" [ngClass]="{'active': sideNav}">
    <div class="sidenav">
      <a class="close-btn" (click)="sideNavClick(false)">&times;</a>
      <a (click)="mobileNav('/project/list')" i18n>Projects</a>
    </div>
  </div>
</div>

