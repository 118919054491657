import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpParams} from "@angular/common/http";
import {DatePipe} from "@angular/common";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private httpWithoutInterceptor: HttpClient;

  constructor(private http: HttpClient, private httpBackend: HttpBackend, private datePipe: DatePipe) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend)
  }

  getOrganizationDetail() {
    return this.http.get(`${environment.urls.organization.organizationInfo}`)
  }

  getOrganizationInfoByID(id: string | null) {
    return this.http.get(`${environment.urls.organization.organizationRelation}${id}/agency_info/`)
  }

  getOrganizationRoleDetail(organizationID: string | null) {
    return this.http.get(`${environment.urls.organization.organizationRoles}${organizationID}/`)
  }

  getOrganizationRolePermissions(filters: any = {}) {
    let params = new HttpParams();
    if (!!filters) {
      if (filters.role) {
        params = params.append('role', `${filters.role}`)
      }
    }
    return this.http.get(`${environment.urls.organization.organizationRolePermission}`, {params: params})
  }

  updateOrganizationDetail(organizationID: string | null, data: any) {
    return this.http.patch(`${environment.urls.organization.organization}${organizationID}/`, data)
  }

  updateOrganizationRelationDetail(id: string | null, data: any) {
    return this.http.patch(`${environment.urls.organization.organizationRelation}${id}/`, data)
  }

  uploadOrganizationFile(data: FormData) {
    return this.http.put(`${environment.urls.organization.organizationFile}`, data)
  }

  getOrganizationInvitations(page: number = 1, pageSize: number = 10, filter: any = null) {
    let params = new HttpParams();
    params = params.append('page', `${page}`)
    params = params.append('page_size', `${pageSize}`)
    if (!!filter) {
      if (filter.role) {
        params = params.append('role', `${filter.role}`)
      }
    }
    return this.http.get(`${environment.urls.organization.organizationInvitations}`, {params: params})
  }

  deleteInvitation(id: string) {
    return this.http.delete(`${environment.urls.organization.organizationInvitations}${id}`)
  }

  createInvitation(data: any) {
    return this.http.post(`${environment.urls.organization.organizationInvitations}`, data)
  }

  bulkCreateInvitation(data: any[]) {
    return this.http.post(`${environment.urls.organization.organizationInvitations}bulk_create/`, data)
  }

  getInvitationInfo(token: string | null) {
    let params = new HttpParams();
    params = params.append('token', `${token}`)
    return this.httpWithoutInterceptor.get(`${environment.urls.organization.organizationInvitations}get_email_by_token/`, {params: params})
  }

  getRelationInvitationInfo(token: string | null) {
    let params = new HttpParams();
    params = params.append('token', `${token}`)
    return this.httpWithoutInterceptor.get(`${environment.urls.organization.organizationRelationInvitation}get_email_by_token/`, {params: params})
  }

  confirmInvitation(id: string | null, data: any) {
    return this.httpWithoutInterceptor.post(`${environment.urls.organization.organizationInvitations}${id}/confirm_invitation/`, data)
  }

  confirmRelationInvitation(data: any) {
    return this.http.post(`${environment.urls.organization.organizationRelationInvitation}confirm_invitation/`, data)
  }

  resendInvitation(id: string | null, data: any) {
    return this.http.post(`${environment.urls.organization.organizationInvitations}${id}/new_email/`, data)
  }

  resendRelationInvitation(id: string | null, data: any) {
    return this.http.post(`${environment.urls.organization.organizationRelationInvitation}${id}/new_email/`, data)
  }

  confirmRelationInvitationWithNewOrganization(data: any) {
    return this.httpWithoutInterceptor.post(`${environment.urls.organization.organizationRelationInvitation}confirm_invitation_with_new_org/`, data)
  }

  changeInvitationPreferredEmail(data: any) {
    return this.httpWithoutInterceptor.post(`${environment.urls.organization.organizationRelationInvitation}change_preferred_email/`, data)
  }


  getRoles(page: number = 1, pageSize: number = 50, filter: any = null) {
    let params = new HttpParams();
    params = params.append('page', `${page}`)
    params = params.append('page_size', `${pageSize}`)
    return this.http.get(`${environment.urls.organization.organizationRoles}`, {params: params})
  }

  getOrganizationRoles(page: number = 1, pageSize: number = 50, filter: any = null) {
    let params = new HttpParams();
    params = params.append('page', `${page}`)
    params = params.append('page_size', `${pageSize}`)
    if (!!filter) {
      if (filter.search) {
        params = params.append('search', `${filter.search}`)
      }
    }
    return this.http.get(`${environment.urls.organization.organizationRoles}`, {params: params})
  }

  getOrganizations(page: number = 1, pageSize: number = 50, filter: any = null) {
    let params = new HttpParams();
    params = params.append('page', `${page}`)
    params = params.append('page_size', `${pageSize}`)
    if (!!filter) {
      if (filter.search) {
        params = params.append('search', `${filter.search}`)
      }
    }
    return this.http.get(`${environment.urls.organization.organization}`, {params: params})
  }

  getOrganizationRelations(page: number = 1, pageSize: number = 50, filter: any = null) {
    let params = new HttpParams();
    params = params.append('page', `${page}`)
    params = params.append('page_size', `${pageSize}`)
    if (!!filter) {
      if (filter.search) {
        params = params.append('search', `${filter.search}`)
      }
      if (filter.project) {
        params = params.append('project', `${filter.project}`)
      }
      if (filter.unitExclude) {
        params = params.append('unit_exclude', `${filter.unitExclude}`)
      }
    }
    return this.http.get(`${environment.urls.organization.organizationRelation}`, {params: params})
  }

  getOrganizationRelationDetail(id: string | null) {
    return this.http.get(`${environment.urls.organization.organizationRelation}${id}/`)
  }

  getOrganizationRelationInvitation(page: number = 1, pageSize: number = 10, filters: any = null) {
    let params = new HttpParams();
    params = params.append('page', `${page}`)
    params = params.append('page_size', `${pageSize}`)
    if (!!filters) {
      if ('search' in filters && !!filters.search) {
        params = params.append('search', `${filters.search}`)
      }
      if ('accessibility' in filters && !!filters.accessibility) {
        params = params.append('accessibility', `${filters.accessibility}`)
      }
    }
    return this.http.get(`${environment.urls.organization.organizationRelationInvitation}`, {params: params})
  }

  createOrganizationInvitation(data: any) {
    return this.http.post(`${environment.urls.organization.organizationRelationInvitation}`, data)
  }

  bulkCreateOrganizationInvitation(data: any) {
    return this.http.post(`${environment.urls.organization.organizationRelationInvitation}bulk_create/`, data)
  }

  deleteOrganizationInvitation(id: string | null) {
    return this.http.delete(`${environment.urls.organization.organizationRelationInvitation}${id}/`)
  }

  getOrganizationMembershipInfo() {
    return this.http.get(`${environment.urls.organization.organization}membership_info/`, {})
  }

  createProAccount(data: any) {
    return this.http.post(`${environment.urls.organization.proAccount}`, data)
  }


  deleteProAccount(id: string | null = null) {
    return this.http.delete(`${environment.urls.organization.proAccount}${id}/`)
  }

  createMembershipEnquiry(data: any) {
    return this.http.post(`${environment.urls.organization.membershipEnquiry}`, data)
  }

  addOrganizationInvitationProject(id: string|null = null, data: any) {
    return this.http.post(`${environment.urls.organization.organizationRelationInvitation}${id}/add_projects/`, data)
  }
}
