import {ChangeDetectorRef, Injectable} from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot, Router
} from '@angular/router';
import {catchError, first, map, Observable, of, take} from 'rxjs';
import {ProjectService} from "../services/project.service";
import {ProjectHubListingResponse, ProjectListingResponse} from "../interfaces/project.interface";
import {LanguageService} from "../services/language.service";
import {NavigationService} from "../services/navigation.service";
import {AddressService} from "../services/address.service";
import {UserService} from "../services/user.service";
import {environment} from "../../../environments/environment";
import posthog from "posthog-js";

@Injectable({
  providedIn: 'root'
})
export class ProjectsResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProjectListingResponse> {
    return this.projectService.getProjects()
  }
}


@Injectable({
  providedIn: 'root'
})
export class ProjectsHubResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProjectHubListingResponse> {
    return this.projectService.getHubProjects();
  }
}


@Injectable({
  providedIn: 'root'
})
export class ProjectProfileResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router, private userService: UserService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.userService.getCacheUser((userInfo, isFromCache) => {
      if (userInfo && environment.posthog.enabled) {
        posthog.identify(
          userInfo?.email,
          {
            companyId: userInfo?.agency?.uuid,
            companyName: userInfo?.agency?.name,
            firstName: userInfo?.first_name,
            lastName: userInfo?.last_name
          },
        );
      }
    })
    if (!route.paramMap.get('id')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getProjectProfile(route.paramMap.get('id')).pipe(catchError((err: any) => {
      this.router.navigateByUrl(`/project/list`)
      throw err
    }))
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectPromotionResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('id')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getProjectLatestPromotion(route.paramMap.get('id')).pipe(catchError((err: any) => {
      this.router.navigateByUrl(`/project/list`)
      throw err
    }))
  }
}


@Injectable({
  providedIn: 'root'
})
export class ProjectProfileDescriptionResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService,
              private router: Router,
              private languageService: LanguageService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('id')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getProjectDescriptionDetail(route.paramMap.get('id'), this.languageService.currentLanguage).pipe(catchError((err: any) => {
      this.router.navigateByUrl(`/project/list`)
      throw err
    }));
  }
}


@Injectable({
  providedIn: 'root'
})
export class ProjectTrainingResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('id')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getProjectCourses(route.paramMap.get('id')).pipe(catchError((err: any) => {
      this.router.navigateByUrl(`/project/list`)
      throw err
    }))
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectTrainingCourseResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('trainingID')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getProjectCourse(route.paramMap.get('trainingID'))
  }
}


@Injectable({
  providedIn: 'root'
})
export class ProjectTrainingCourseQAResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('trainingID')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getProjectCourseQA(route.paramMap.get('trainingID'))
  }
}

@Injectable({
  providedIn: 'root'
})
export class ResourceResolver implements Resolve<Object> {
  constructor(private navigationService: NavigationService,
              private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('id')) {
      this.router.navigateByUrl(`/project/list`)
    }
    this.navigationService.updateNav({
      location: "project-resource",
      projectId: route.paramMap.get('id'),
      resourceId: null,
      resourceLabel: null,
    });
    return new Observable(subscriber => {
      subscriber.next(1);
    }).pipe(first());
  }
}


@Injectable({
  providedIn: 'root'
})
export class ProjectBuildingResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('id')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getBuildings(route.paramMap.get('id')).pipe(catchError((err: any) => {
      this.router.navigateByUrl(`/project/list`)
      throw err
    }));
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectLevelPlanResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('id')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getLevelPlan(route.paramMap.get('id')).pipe(catchError((err: any) => {
      this.router.navigateByUrl(`/project/list`)
      throw err
    }), map((x: any) => {
      return x.map((item: any) => {
        return {
          id: item.uuid,
          name: item.name,
          files: item.files,
          description: item.description
        }
      })
    }));
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectFloorPlanResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('id')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getFloorPlan(route.paramMap.get('id')).pipe(catchError((err: any) => {
      this.router.navigateByUrl(`/project/list`)
      throw err
    }), map((x: any) => {
      return x.map((i: any) => {
        return {
          id: i.uuid,
          name: i.name,
          files: i.files,
          description: i.description,
        }
      })
      // return {
      //   ...x,
      //   results: x.results.map((item: any) => {
      //     return {
      //       id: item.uuid,
      //       name: item.name,
      //       files: item.files,
      //       description: item.description
      //     }
      //   })
      // }
    }));
  }
}


@Injectable({
  providedIn: 'root'
})
export class ProjectLevelDetailResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('id') || !route.paramMap.get('levelID')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getLevelPlanDetail(route.paramMap.get('levelID')).pipe(catchError((err: any) => {
      this.router.navigateByUrl(`/project/list`)
      throw err
    }));
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectFloorPlanDetailResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('id')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getFloorPlanDetail(route.paramMap.get('floorPlanID'));
  }
}


@Injectable({
  providedIn: 'root'
})
export class ProjectUnitDetailResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('id')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getUnitDetail(route.paramMap.get('unitID')).pipe(catchError((err: any) => {
      this.router.navigateByUrl(`/project/list`)
      throw err
    }));
  }
}


@Injectable({
  providedIn: 'root'
})
export class ProjectPriceIndexResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('id')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getLowMaintainPriceRange(route.paramMap.get('id')).pipe(catchError((err: any) => {
      this.router.navigateByUrl(`/project/list`)
      throw err
    }));
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectPriceRangeResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('id')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getProjectPriceRange(route.paramMap.get('id')).pipe(catchError((err: any) => {
      this.router.navigateByUrl(`/project/list`)
      throw err
    }));
  }
}


@Injectable({
  providedIn: 'root'
})
export class ProjectUnitTagResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('unitID')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getUnitRelationTags(route.paramMap.get('unitID'));
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectSuburbs implements Resolve<Object> {
  constructor(private addressService: AddressService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    const uuids = route.queryParamMap.getAll('suburb')
    if (!uuids || uuids.length <= 0) {
      return of([])
    }
    return this.addressService.getSuburb({uuids: uuids});
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectOptions implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('id')) {
      this.router.navigateByUrl(`/project/list`)
    }
    if (route.data && route.data['planType']) {
      if (route.data['planType'] === 'level-plan' && route.paramMap.get('levelID')) {
        return this.projectService.getProjectOptions(route.paramMap.get('id'), {level: route.paramMap.get('levelID')}).pipe(catchError((err: any) => {
          this.router.navigateByUrl(`/project/list`)
          throw err
        }))
      }
      if (route.data['planType'] === 'floor-plan' && route.paramMap.get('floorPlanID')) {
        return this.projectService.getProjectOptions(route.paramMap.get('id'), {floor_plan: route.paramMap.get('floorPlanID')}).pipe(catchError((err: any) => {
          this.router.navigateByUrl(`/project/list`)
          throw err
        }))
      }
    }

    return this.projectService.getProjectOptions(route.paramMap.get('id'), {}).pipe(catchError((err: any) => {
      this.router.navigateByUrl(`/project/list`)
      throw err
    }))
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProjectLevelOptionsResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('id')) {
      this.router.navigateByUrl(`/project/list`)
    }
    return this.projectService.getLevelPlan(route.paramMap.get('id'));
  }
}


@Injectable({
  providedIn: 'root'
})
export class ProjectProposalResolver implements Resolve<Object> {
  constructor(private projectService: ProjectService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProjectListingResponse> {
    if (!route.paramMap.get('id') || !route.paramMap.get('proposalID')) {
      this.router.navigateByUrl(`/project/list`);
    }

    // @ts-ignore
    return this.projectService.getProposalDetail(route.paramMap.get('proposalID'));
  }
}
