import {Injectable} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {UserService} from "../services/user.service";
import {OrganizationService} from "../services/organization.service";

@Injectable({
  providedIn: 'root'
})
export class OrganizationResolver implements Resolve<boolean> {
  constructor(private organizationService: OrganizationService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.organizationService.getOrganizationDetail();
  }
}

@Injectable({
  providedIn: 'root'
})
export class OrganizationRelationResolver implements Resolve<boolean> {
  constructor(private organizationService: OrganizationService, private router: Router) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!route.paramMap.get('relationID')) {
      this.router.navigateByUrl(`/manage/organizations`)
    }
    return this.organizationService.getOrganizationInfoByID(route.paramMap.get('relationID'));
  }
}
