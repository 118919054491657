import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {map} from "rxjs";
import {DatePipe} from "@angular/common";
import {DomSanitizer} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient, private datePipe: DatePipe, private sanitizer: DomSanitizer) {
  }

  getNotification(page: number = 1, pageSize: number = 10, filters: any) {
    let params = new HttpParams();
    params = params.append('page', `${page}`)
    params = params.append('page_size', `${pageSize}`)
    if (!!filters) {
      if (!!filters.type) {
        params = params.append('type', `${filters.type}`)
      }
      if (!!filters.category) {
        params = params.append('category', `${filters.category}`)
      }
    }
    return this.http.get(`${environment.urls.notification.apiNotification}`, {params: params}).pipe(
      map((item: any) => {
        return {
          ...item,
          results: item.results.map((x: any) => {
            return {
              ...x,
              content: this.sanitizer.bypassSecurityTrustHtml(x.content),
              created_at: this.datePipe.transform(x.created_at, 'yyyy-MM-dd'),
              updated_at: this.datePipe.transform(x.updated_at, 'yyyy-MM-dd')
            }
          })
        }
      })
    )
  }

  getOrganizationMessage(page: number, pageSize: number, filters: any) {
    let params = new HttpParams();
    params = params.append('page', `${page}`)
    params = params.append('page_size', `${pageSize}`)
    if (!!filters) {
      if (!!filters.type && filters.type !== 'all') {
        params = params.append('type', `${filters.type}`)
      }
      if (!!filters.search) {
        params = params.append('search', `${filters.search}`)
      }
    }
    return this.http.get(`${environment.urls.notification.organizationMessage}`, {params: params})
  }

  getSentOrganizationMessage(page: number, pageSize: number, filters: any) {
    let params = new HttpParams();
    params = params.append('page', `${page}`)
    params = params.append('page_size', `${pageSize}`)
    params = params.append('sender', `self`)
    if (!!filters) {
      if (!!filters.type) {
        if (filters.type === 'all') {
          params = params.append('type', `general`)
          params = params.append('type', `promotion`)
        } else {
          params = params.append('type', `${filters.type}`)
        }

      }
    }
    return this.http.get(`${environment.urls.notification.organizationMessage}`, {params: params})
  }

  createOrganizationMessage(data: any) {
    return this.http.post(`${environment.urls.notification.organizationMessage}`, data);
  }

  addMessageReceivers(id: string | null, data: any) {
    return this.http.post(`${environment.urls.notification.organizationMessage}${id}/add_receivers/`, data);
  }

  addMessageProjects(id: string | null, data: any) {
    return this.http.post(`${environment.urls.notification.organizationMessage}${id}/add_projects/`, data);
  }

  addMessageFiles(id: string | null, data: any) {
    return this.http.post(`${environment.urls.notification.organizationMessage}${id}/add_files/`, data);
  }

  deleteOrgMessage(id: string | null) {
    return this.http.delete(`${environment.urls.notification.organizationMessage}${id}/`)
  }

  updateOrganizationMessage(id: string | null, data: any) {
    return this.http.patch(`${environment.urls.notification.organizationMessage}${id}/`, data);
  }

  addOrgMessageReceiver() {

  }

  addOrgMessageFile(data: any) {
    return this.http.post(`${environment.urls.notification.organizationMessageFile}`, data)
  }

  deleteOrgMessageFile(id: string | null) {
    return this.http.delete(`${environment.urls.notification.organizationMessageFile}${id}/`)
  }

  createPromotion(data: any) {
    return this.http.post(`${environment.urls.notification.promotion}`, data)
  }

  acceptMessageInvitation(id: string | null) {
    return this.http.post(`${environment.urls.notification.organizationMessage}${id}/accept_invitation/`, {})
  }

  registerExpoToken(token: string) {
    return this.http.post(`${environment.urls.notification.registerExpoToken}`, {"token": token})
  }

  deregisterExpoToken(token: string) {
    return this.http.post(`${environment.urls.notification.deregisterExpoToken}`, {"token": token})
  }

}
