import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {tap} from "rxjs";
import {environment} from "../../../environments/environment";
import {UserCredential, UserInfo} from "../interfaces/user-crendential.interface";
import {PermissionService} from "./permission.service";
import {AppService} from "./app.service";
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private httpWithoutInterceptor: HttpClient;

  constructor(private http: HttpClient,
              private permService: PermissionService,
              private userService: UserService,
              // private appService: AppService,
              private httpBackend: HttpBackend, private router: Router) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend)
  }

  isLoggedIn() {
    return !!localStorage.getItem('currentUser')
  }

  public get currentUserValue(): UserInfo {
    return JSON.parse(<string>localStorage.getItem('currentUser'))
  }

  getCurrentUserValueString() {
    return <string>localStorage.getItem('currentUser')
  }


  login(credential: UserCredential) {
    return this.httpWithoutInterceptor.post<any>(`${environment.urls.auth.apiTokenURL}`, {
      email: credential.email,
      password: credential.password
    })
      .pipe(
        tap(res => {
          localStorage.setItem('currentUser', JSON.stringify(res))
        }),
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.userService.delCacheUser();
    this.permService.logout();
    this.router.navigate(['/auth/login']);
  }

  getAccessToken() {
    let currentUserString = localStorage.getItem('currentUser');
    if (currentUserString != null) {
      const currentUser = JSON.parse(currentUserString);
      return currentUser.access
    } else {
      this.logout();
      return null
    }

  }

  refresh() {
    return this.httpWithoutInterceptor.post<any>(`${environment.urls.auth.apiTokenRefreshURL}`, {refresh: this.currentUserValue.refresh},)
      .pipe(
        tap((tokens: any) => {
          let current = JSON.parse(<string>localStorage.getItem('currentUser'))
          localStorage.setItem('currentUser', JSON.stringify({
            access: tokens.access,
            refresh: current.refresh,
          }));
          // // Update App Token
          // this.appService.updateAuthTokenRequest();
        }),
      );
  }
}
