// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {ProjectService} from "../app/core/services/project.service";
import {ProjectMockService} from "../app/core/services/project.mock.service";

export const environment = {
  production: true,
  code: 'prod',
  platformAdmin: 'super_admin@realtycms.com.au',
  templates: {
    bulkImport: 'https://realtycms-public-dev.s3.ap-southeast-2.amazonaws.com/assets/templates/RealtyCMS_Bulk_Import_Template.xlsx',
    bulkImportManual: 'https://realtycms-public-dev.s3.ap-southeast-2.amazonaws.com/assets/templates/Bulk_Manual.pdf',
  },
  providers: [],
  sites: {
    backend: 'https://dev-api.realtycms.com.au/',
    publicMedia: 'https://dev-media.realtycms.com.au/',
  },
  buckets: {
    publicBucket: 'realtycms-public-dev',
    privateBucket: 'realtycms-private-dev'
  },
  urls: {
    auth: {
      apiTokenURL: 'https://dev-api.realtycms.com.au/api/token/',
      apiTokenRefreshURL: 'https://dev-api.realtycms.com.au/api/token/refresh/',
    },
    address: {
      apiAddressURL: 'https://dev-api.realtycms.com.au/api/address/',
      apiSuburbURL: 'https://dev-api.realtycms.com.au/api/suburb/',
    },
    language: {
      language: 'https://dev-api.realtycms.com.au/api/language/'
    },
    project: {
      apiProjectURL: 'https://dev-api.realtycms.com.au/api/project/',
      apiProjectConfigURL: 'https://dev-api.realtycms.com.au/api/project_config/',
      apiProjectUnitURL: 'https://dev-api.realtycms.com.au/api/unit/',
      apiProjectSuburbCensus: 'https://dev-api.realtycms.com.au/api/suburb_census/',
      apiProjectSchoolInfo: 'https://dev-api.realtycms.com.au/api/school/',
      apiInternalProjectURL: 'https://dev-api.realtycms.com.au/api/organization/project/',
      apiProjectSpecialURL: 'https://dev-api.realtycms.com.au/api/projects/',
      apiProjectHubURL: 'https://dev-api.realtycms.com.au/api/hub_projects/',
      apiProjectApplicationURL: 'https://dev-api.realtycms.com.au/api/application/',
      apiProjectCollectURL: 'https://dev-api.realtycms.com.au/api/selected_project/',
      apiProjectDescription: 'https://dev-api.realtycms.com.au/api/project_language/',
      apiCache: 'https://dev-api.realtycms.com.au/api/cache/',
      apiProjectUnit: 'https://dev-api.realtycms.com.au/api/unit/',
      apiUnitTag: 'https://dev-api.realtycms.com.au/api/unit_tag/',
      apiUnitTagRelation: 'https://dev-api.realtycms.com.au/api/unit_tag_relation/',
      apiUnitCache: 'https://dev-api.realtycms.com.au/api/unit_cache/',
      apiProjectMedia: 'https://dev-api.realtycms.com.au/api/organization/projects/',
      apiProjectLevel: 'https://dev-api.realtycms.com.au/api/level/',
      apiProjectLevelPlanImage: 'https://dev-api.realtycms.com.au/api/level_plan/',
      apiProjectFloorPlan: 'https://dev-api.realtycms.com.au/api/floor_plan/',
      apiProjectFloorPlanImage: 'https://dev-api.realtycms.com.au/api/floor_plan_image/',
      apiProjectImage: 'https://dev-api.realtycms.com.au/api/project_image/',
      apiProjectVideo: 'https://dev-api.realtycms.com.au/api/project_video/',
      apiProjectVR: 'https://dev-api.realtycms.com.au/api/project_vr/',
      apiProjectCourse: 'https://dev-api.realtycms.com.au/api/project_course/',
      apiProjectCourseQA: 'https://dev-api.realtycms.com.au/api/project_course_qa/',
      apiProjectNews: 'https://dev-api.realtycms.com.au/api/news/',
      apiProjectPromotion: 'https://dev-api.realtycms.com.au/api/promotion/',
      apiProjectPromotionUnitRelation: 'https://dev-api.realtycms.com.au/api/promotion_unit/',
      apiProjectBuilding: 'https://dev-api.realtycms.com.au/api/building/',
      apiProjectPriceIndex: 'https://dev-api.realtycms.com.au/api/project_price_index/',
      apiProjectInvitation: 'https://dev-api.realtycms.com.au/api/invitation/',
      apiProjectBatchInvitation: 'https://dev-api.realtycms.com.au/api/batch_invitation/',
      apiProjectAgentRelation: 'https://dev-api.realtycms.com.au/api/project_agent_relation/',
      apiProjectAgentRelationExtra: 'https://dev-api.realtycms.com.au/api/project_agent_relation_extra/',
      apiUnitAgentRelation: 'https://dev-api.realtycms.com.au/api/unit_agent_relation/',
      apiProjectFileUploadPreSignedUrl: 'https://dev-api.realtycms.com.au/api/file_upload_link/',
      apiProjectFileGetPreSignedUrl: 'https://dev-api.realtycms.com.au/api/file_link/',
      apiProjectListExport: 'https://dev-api.realtycms.com.au/api/project/export_project_list/',
      apiProjectProposal: 'https://dev-api.realtycms.com.au/api/proposal/',
    },
    notification: {
      apiNotification: 'https://dev-api.realtycms.com.au/api/notification/',
      organizationMessage: 'https://dev-api.realtycms.com.au/api/org_message/',
      organizationMessageFile: 'https://dev-api.realtycms.com.au/api/org_message_file/',
      promotion: 'https://dev-api.realtycms.com.au/api/promotion/',
      registerExpoToken: 'https://dev-api.realtycms.com.au/api/exp_push_token/',
      deregisterExpoToken: 'https://dev-api.realtycms.com.au/api/exp_push_token/deregister/',
    },
    staff: {
      staff: 'https://dev-api.realtycms.com.au/api/organisation_staff/',
      staffInfo: 'https://dev-api.realtycms.com.au/api/organisation_staff/user_info/',
      staffFile: 'https://dev-api.realtycms.com.au/api/organization/staffs/',
      passwordReset: 'https://dev-api.realtycms.com.au/api/password_reset/',
      passwordResetConfirm: 'https://dev-api.realtycms.com.au/api/password_reset/confirm/',
      passwordTokenCheck: 'https://dev-api.realtycms.com.au/api/password_reset/validate_token/ '
    },
    organization: {
      organization: 'https://dev-api.realtycms.com.au/api/organisation/',
      organizationRelation: 'https://dev-api.realtycms.com.au/api/organisation_rel/',
      organizationRelationInvitation: 'https://dev-api.realtycms.com.au/api/organisation_rel_invitation/',
      organizationInfo: 'https://dev-api.realtycms.com.au/api/organisation/organization_info/',
      organizationFile: 'https://dev-api.realtycms.com.au/api/organization/files/',
      organizationRoles: 'https://dev-api.realtycms.com.au/api/organisation_role/',
      organizationRolePermission: 'https://dev-api.realtycms.com.au/api/organisation_role_permission/',
      organizationInvitations: 'https://dev-api.realtycms.com.au/api/staff_invitation/',
      permissionListUser: 'https://dev-api.realtycms.com.au/api/permission/list_my_permission/',
      userRoleVersion: 'https://dev-api.realtycms.com.au/api/permission/my_role_version/',
      membership: 'https://dev-api.realtycms.com.au/api/membership/',
      proAccount: 'https://dev-api.realtycms.com.au/api/pro_account/',
      membershipEnquiry: 'https://dev-api.realtycms.com.au/api/membership_enquiry/',
    },
    quickSearch: {
      search: 'https://dev-api.realtycms.com.au/api/search/',
    },
    service: {
      thumbnail: "https://service.realtycms.com.au/image/",
      getShare: "https://service.realtycms.com.au/get-share/",
      share: "https://service.realtycms.com.au/share/",
      pdfImages: "https://ayjh9ieoel.execute-api.ap-southeast-2.amazonaws.com/images"
    },
    system: {
      apiFrontendInfoURL: 'https://dev-api.realtycms.com.au/api/current_frontend_info/',
    }

  },
  posthog: {
    enabled: false,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
