import Bugsnag from '@bugsnag/js'
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular'
import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from "./shared/shared.module";
import {HttpClientModule, HttpClientXsrfModule} from "@angular/common/http";
import {CoreModule} from "./core/core.module";
import {environment} from "../environments/environment";
import {GoogleMapsModule} from "@angular/google-maps";
import {MatTooltipModule} from "@angular/material/tooltip";

Bugsnag.start({apiKey: '8fe7d6d6e9902cb7631639f111e32c2a'})

export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

let providers = [];

if (environment.production) {
  providers.push({provide: ErrorHandler, useFactory: errorHandlerFactory});
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    CoreModule,
    MatTooltipModule,
    HttpClientXsrfModule.withOptions({cookieName: 'csrftoken', headerName: 'X-CSRFToken'}),
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule {
}
