import {Injectable} from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {catchError, Observable, of} from 'rxjs';
import {UserService} from "../services/user.service";
import {AuthenticationService} from "../services/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<boolean> {
  constructor(private userService: UserService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    if (!route.paramMap.get('id')) {
      return this.userService.getUserInfo();
    }
    return this.userService.getUserInfoByID(route.paramMap.get('id')).pipe(catchError((err: any) => {
      this.router.navigateByUrl(`/project/list`)
      throw err
    }));
  }
}


@Injectable({
  providedIn: 'root'
})
export class UserInfoResolver implements Resolve<boolean> {
  constructor(private userService: UserService, private authService: AuthenticationService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    if (!this.authService.isLoggedIn()) {
      this.router.navigateByUrl(`/auth/login`);
      return of(null)
    }
    return this.userService.getUserInfo();
  }
}
