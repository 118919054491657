import {Component, OnInit, Renderer2} from '@angular/core';
import {SystemService} from "./core/services/system.service";
import {AppService} from "./core/services/app.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'project-partner-front-end';

  constructor(private systemService: SystemService,
              private appService: AppService) {
  }

  ngOnInit() {
    this.systemService.initVersionCheck();
    window.addEventListener("message",
      message => {
        if (message.type == "message") {
          this.appService.appAdapter(message.data);
        }
      });
    document.addEventListener("message", message => {
      // This is for android
      if (message.type == "message") {
        // @ts-ignore
        this.appService.appAdapter(message.data);
      }
    });
  }

}
